<section class="main-banner">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" href="#">
          <img src="../../assets/zoome.svg" alt="zoome ecommerce solutions" width="75%" height="100%" class="img-fluid">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><img src="../../assets/nav_icon.png" class="img-fluid" /> </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" (click)="scroll(targetfeatures)">Features</a>
            </li>
            <!-- <li class="nav-item">
          <a class="nav-link" href="#">Why Us</a>
        </li> -->
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetcms)">CMS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetcrypto)">Crypto Payments</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" routerLink="#" aria-disabled="true">How to buy $ZOE</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetabout)">About us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetimprint)">Imprint</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetfaq)">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scroll(targetcontact)">Contact</a>
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0 ml">
            <ul class="list-inline">
              <div class="mt-3">
                <li class="list-inline-item">
                  <!--<a class="btn-common" routerLink="#" aria-disabled="true">Buy $ZOE</a>-->
                  <a class="btn-common" routerLink="/uniswap" aria-disabled="true">Buy $ZOE</a>
                </li>
              </div>
            </ul>
          </form>
          <!-- <form class="form-inline my-2 my-lg-0 ml">
          <ul class="list-inline">
              <li class="list-inline-item" >
                <a  class="btn-common" target="_blank">Sign up</a>
              </li>
              <li class="list-inline-item" >
                  <a  class="btn-common" target="_blank">Login</a>
                </li>
          </ul>
        </form> -->
        </div>
      </nav>

        <div class="row ptb">
            <div class="col-sm-7 animate__animated animate__backInLeft">
                <div class="inner-box" #buy>
                    <h1>Build your own<span class="common-g"> <br>Website and Shop - quickly,</span> <br>easily, professionally</h1>
                    <p>ZOOME is the high-performance CMS system that is easy to use, without any programming
                        knowledge. The integrated shop function gives you the opportunity to start your eCommerce
                        business in a short time. And offer your customer their own DeFi and NFT marketplace</p>
                    <ul class="list-inline for-m">
                        <li class="list-inline-item">
                            <a class="btn-common" routerLink="/uniswap" aria-disabled="true">Buy Token</a>
                        </li>
                        <li class="list-inline-item">
                            <a class="btn-common" href="../../assets/ZOOME white paper.pdf" target="_blank">White Paper</a>
                        </li>
                    </ul>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Crypto</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Fiat</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Card</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-5 animate__animated animate__backInRight">
                <div class="inner-boxy text-center">
                    <img src="../../assets/header-image.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features ptb" #targetfeatures>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2 class="main-color text-center">
                    Why Choose
                    <span class="common-g"> ZOOME</span>
                </h2>
                <span class="border1"></span>
            </div>
        </div>

        <div class="row ptb">
            <div class="col-sm-4">
                <div class="inner-side">
                    <img src="../../assets/payment-with-crypto-img.png" alt="" class="img-fluid">
                    <h3 class="main-color">Easy to use</h3>
                    <p>ZOOME offers a variety of widgets with which you can create beautiful websites without
                        programming knowledge.</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="inner-side">
                    <img src="../../assets/save-money-img.png" alt="" class="img-fluid">
                    <h3 class="main-color">
                        Webshop Integration
                    </h3>
                    <p>Our CMS includes a pre-installed shop module with which you can start your eCommerce
                        business in just a few steps.</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="inner-side">
                    <img src="../../assets/get-help-fast-img.png" alt="" class="img-fluid">
                    <h3 class="main-color">
                        Crypto & Fiat Payment
                    </h3>
                    <p>The ZOOME shop module allows you to offer crypto currency as a payment method. Get the
                        competitive advantage.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="round ptb" #targetcms>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="inner-img">
                    <img src="../../assets/the-easiest-way-img.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="inner-text">
                    <h2>The easiest way to <br>make <span class="common-g">your own website</span></h2>
                    <p class="p1">Create your fully responsive high-performance website using drag and drop. Our CMS
                        system
                        offers you numerous exciting and effective advertising widgets and creative, pre-designed
                        templates that leave a lasting impression on your visitors.</p>
                    <ul class="list-inline for-m">
                        <!-- <li class="list-inline-item">
                            <a class="btn-common" target="_blank">Get Started</a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="round2 ptb" #targetcrypto>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="inner-text">
                    <h2>
                        <span class="common-g">Forward-looking:</span> Shop with crypto currency
                    </h2>
                    <p class="p1">Everyone is talking about crypto right now. So why not integrate this currency into a
                        shop
                        system? Be one of the first to enable your customers to pay for products with Bitcoin, Ethereum
                        and Co.</p>
                    <ul class="list-inline for-m">
                        <!-- <li class="list-inline-item">
                            <a class="btn-common" target="_blank">Get Started</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="inner-img">
                    <img src="../../assets/payment-img.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="token">
   <div class="container">
       <div class="row ptb">
           <div class="col-sm-12 text-center">
               <h2 class="main-color"><strong>Token Distribution</strong></h2>
           </div>
       </div>
       <div class="row ptb">
           <div class="col-sm-6">
               <div class="inner-tile">
                   <div class="row">
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Name:
                               <h6 class="main-color">Candy Token</h6>
                           </div>
                       </div>
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Starts:
                               <h6 class="main-color">April 8</h6>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Exchange Rate:
                               <h6 class="main-color">1ETH = 100,000 CANDY</h6>
                           </div>
                       </div>
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Ends:
                               <h6 class="main-color">April 10</h6>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Currencies Accepted:
                               <h6 class="main-color">ETH</h6>
                           </div>
                       </div>
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Hard Cap:
                               <h6 class="main-color">125,000,000 CANDY</h6>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-sm-6">
                           <div class="inner-style">
                               <span class="main-color">.</span>
                               Minimum Purchase:
                               <h6 class="main-color">0.1 ETH/10,000 CANDY</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

           <div class="col-sm-6">
               <div class="inner-disc text-center">
                   <img src="../../assets/sweetswap-presale-assets/token-distribution-pie-chart.png" alt="" class="img-fluid">
               </div>
           </div>
       </div>
   </div>
</section> -->

<section class="roadmap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="inner-text text-center">
                    <h2>Choose from our 1000+ templates <span class="common-g">1000+ templates </span></h2>
                    <p>Choose form more than a thousands of carefully prepared and easy to customize templates</p>
                    <span class="border1"></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center">
                <img src="../../assets/templates-img.png" alt="" class="img-fluid">
            </div>
        </div>
        <div class="row ptb">
            <div class="col-sm-12 text-center">
                <ul class="list-inline for-m">
                    <li class="list-inline-item">
                        <a class="btn-common">Discover more templates</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>


<!-- <section class="round ptb">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                 <div class="inner-img">
                     <img src="../../assets/start-selling-img.png" alt="" class="img-fluid">
                 </div>
            </div>
            <div class="col-sm-6">
                <div class="inner-text">
                    <h2>Start Selling at labore et <br>dolore <span class="common-g">magna aliqua</span></h2>
                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <ul class="list-inline for-m">
                         <li class="list-inline-item" >
                             <a  class="btn-common" target="_blank">Get Started</a>
                           </li>
                     </ul>
                 </div>
             </div>
        </div>
    </div>
 </section> -->

<section class="main-banner2">
    <div class="container">
        <div class="row ptb">
            <div class="col-sm-7 animate__animated animate__backInLeft">
                <div class="inner-box p1" #buy>
                    <h2> <span class="common-g">How to become</span> part of the ZOOME family.</h2>
                    <div class="p1">
                        <p><span class="common-g">01.</span> Sign up - it only takes a moment.</p>
                        <p><span class="common-g">02.</span> Easily install the ZOOME shop-files on your web server.</p>
                        <p><span class="common-g">03.</span> Choose a design or create your own one with our easy-to-use
                            page builder.</p>
                        <p><span class="common-g">04.</span> Add images, texts and other content.</p>
                        <p><span class="common-g">05.</span> Publish your website with just one click.</p>
                    </div>
                    <ul class="list-inline for-m">
                        <li class="list-inline-item">
                            <a class="btn-common" routerLink="/uniswap">Buy Token</a>
                        </li>
                        <!-- <li class="list-inline-item">
                            <a class="btn-common" target="_blank">Get Started</a>
                        </li> -->
                    </ul>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Crypto</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Fiat</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="inner-side">
                                <img src="../../assets/tick.png" alt="" class="img-fluid" style="width: 16px;">
                                <span> Payment with Card</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-5 animate__animated animate__backInRight">
                <div class="inner-boxy text-center">
                    <img src="../../assets/how-to-make-website-img.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="roadmap2 ptb" #targetfaq>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-text text-center">
          <h2>FAQ</h2>

          <div class="accordion accordion-flush" id="accordionFlushExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSix">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseThree">
                  How to buy $ZOE?
                </button>
              </h2>
              <div id="flush-collapseSix" class="accordion-collapse collapse show" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">

                  <section class="buy-zoe ptb">
                    <div class="container">
                      <div class="row ">
                        <div class="col-sm-12">
                          <div class="zoe">
                            <h2>How to buy <span class="common-g">$ZOE?</span> </h2>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="">
                            <p><span class="common-g">1.</span> Create an account at <span class="common-g">binance.com, kraken.com,
              bitpanda.com, kukoin.com </span>or an exchanger of
                              your choice. Exchange FIAT (Dollar, Euro, Franc) for Ethereum ($ETH).</p>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="">
                            <p>
                              <span class="common-g">2.</span> The safest place to buy $ZOE is on <span class="common-g">ZOOME.io.</span>
                              Visit <span class="common-g">ZOOME.io </span>and click “connect wallet”
                              MetaMask will ask for your signature, go ahead and sign it.
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-6 ">
                          <div class="">
                            <p><span class="common-g">3.</span> Open your Google Chrome and visit <span
                              class="common-g">metamask.io.</span> Download the MetaMask chrome extension and
                              set up a wallet. On mobile? Get MetaMask’s app for iPhone or Android.</p>

                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="">
                            <p><span class="common-g">4.</span> Set your slippage to 3-4% and enter and the amount of $ETH you would like
                              to swap for
                              $ZOE.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row ">
                        <div class="d-flex col-sm-6 ">
                          <div class="">
                            <p>
                              <span class="common-g">5.</span> Transfer $ETH to your new MetaMask wallet from your existing wallet (e.g.
                              Trust Wallet,
                              Coinbase, or Binance), or buy $ETH directly within MetaMask.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  What is $ZOE?
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">$ZOE is an ERC-20 Token</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Where can I hold $ZOE?
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Any wallet that supports ERC-20 tokens will support $ZOE (i.e. Trust Wallet, Coinbase, or Metamask).
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Is $ZOE on Binance Smart Chain (BSC)?
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">No, $ZOE is not on BSC. Be careful of counterfeits and clones. Please check our “How to buy?" section above.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                  Is $ZOE stackable?
                </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">We are also working on it.</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree">
                  Why am I getting an error when trying to buy on $ZOE or Uniswap?
                </button>
              </h2>
              <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body"> This is likely due to slippage. Click on the gear button in the top right of the window and set your slippage to 4%.
                </div>
              </div>
            </div>

          </div>


          <ul class="list-inline mt-4">
            <li class="list-inline-item">
              <a class="btn-common" routerLink="/uniswap">Buy Token</a>
            </li>
            <!-- <li class="list-inline-item">
                <a class="btn-common">Get Started</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="round ptb" #targetabout>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="inner-img">
          <img src="../../assets/team.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="inner-text">
          <h2 class="common-g">About us</h2>
          <p class="p1" style="color: #000000">ZOOME is a startup company founded by Stefan Klimek, an enthusiastic software developer with
            20 years of experience. Together with Kai Helmerichs, marketeer and project manager with 15
            years of experience, he founded the company in 2021 with the aim of revolutionizing the
            eCommerce market.</p>

          <ul class="list-inline">
            <!-- <li class="list-inline-item">
                <a class="btn-common" target="_blank">Get Started</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="offset-md-3 col-md-3 col-sm-12">
        <div class="card float-md-right">
          <img  class="img-fluid mr-3" src="../../assets/bewerbungsfoto.jpg" alt="ZOOME CEO and Senior Software Engineer Stefan Klimek" title="ZOOME CEO and Senior Software Engineer Stefan Klimek">
          <div class="card-body">
            <h6 class="card-title common-g">Stefan Klimek<br>CEO and Senior Software Engineer</h6>
            <p class="card-text">Stefan Klimek, an enthusiastic software developer with
              20 years of experience.</p>
            <a href="https://www.geccom-media.de" class="btn btn-common" target="_blank">more infos about Stefan</a>
          </div>
        </div>
      </div>

      <div class=" col-md-3 col-sm-12">
        <div class="card float-md-right">
          <img  class="img-fluid mr-3" src="../../assets/WhatsAppKai.png" alt="ZOOME CMO and Art Director Kai Helmerichs" title="ZOOME CMO and Art Director Kai Helmerichs">
          <div class="card-body">
            <h6 class="card-title common-g">Kai Helmerichs<br>CMO and Art Director</h6>
            <p class="card-text">Kai Helmerichs, marketeer and project manager with 15
              years of experience.</p>
            <a href="https://www.kh-designs.de/" class="btn btn-common" target="_blank">more infos about Kai</a>
          </div>
        </div>
      </div>

        <div class=" col-md-3 col-sm-12">
          <div class="card float-md-right">
            <img  class="img-fluid mr-3" src="../../assets/eric.jpg" alt="ZOOME CCM and Junior Web Developer" title="ZOOME CCM and Junior Web Developer Endrit Morina">
            <div class="card-body">
              <h6 class="card-title common-g">Endrit Morina<br>CCM and Junior Web Developer</h6>
              <p class="card-text">Endrit Morina, CCM with a huge knowledge in the cryptoworld.</p>
              <a href="#" class="btn btn-common is-disabled" target="_blank">infos coming soon</a>
            </div>
          </div>
        </div>


      </div>
    </div>
</section>


<section class="round2 ptb" #targetimprint>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="inner-text">
          <h2 class="common-g">
            Imprint
          </h2>
          <p class="p1" style="color: #000000;">
            ZOOME is a company of geccom media UG<br>
            Design- and eCommerceconcepts<br><br>

            Fluthgrafstraße 12<br>
            D-46483 Wesel<br><br>

            <a href="mailto:info@zoome.io" class="btn-common">info@zoome.io</a><br><br>

            Amtsgericht Duisburg<br>
            HRB: 31883
          </p>
          <ul class="list-inline for-m">
          </ul>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="inner-img">
          <img src="../../assets/imprint.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="roadmap2 ptb" #targetcontact>
    <div class="container">
        <div class="row">
          <div class="col-sm-6">
           <!-- <form [formGroup]="validatingForm" class="form-inline">
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form md-outline form-lg">
                    <input name="nachname" class="form-control form-control-lg form-control" type="text" placeholder="Your name*" formControlName="nachnameInput">
                    <mdb-error *ngIf="nachnameInput.invalid && (nachnameInput.dirty || nachnameInput.touched)">Eingabe ungültig</mdb-error>
                    <mdb-success *ngIf="nachnameInput.valid && (nachnameInput.dirty || nachnameInput.touched)">Eingabe gültig</mdb-success>
                  </div>
                </div>
              </div>
                <div class="row">
                <div class="col-md-6">
                  <div class="md-form md-outline form-lg">
                    <input name="email" class="form-control form-control-lg form-control" type="email" placeholder="Your email*" formControlName="emailInput">
                    <mdb-error *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">Eingabe ungültig</mdb-error>
                    <mdb-success *ngIf="emailInput.valid && (emailInput.dirty || emailInput.touched)">Eingabe gültig</mdb-success>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-md-12">
                  <div class="md-form md-outline">
                    <textarea type="text" name="nachricht" class="form-control md-textarea form-control mb-2 mr-md-2" rows="5" placeholder="Your message*" formControlName="nachrichtInput"></textarea>
                    <mdb-error *ngIf="nachrichtInput.invalid && (nachrichtInput.dirty || nachrichtInput.touched)">Eingabe ungültig</mdb-error>
                    <mdb-success *ngIf="nachrichtInput.valid && (nachrichtInput.dirty || nachrichtInput.touched)">Eingabe gültig</mdb-success>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button class="btn btn-common" type="submit" (click)="onSubmit()" [disabled]="!validatingForm.valid"><span>Send</span></button>
                </div>
              </div>
            </form>-->

              <form [formGroup]="validatingForm" (ngSubmit)="onSubmit()" novalidate>
              <div class="form-group">
                <label>Name*</label>
                <input type="text" formControlName="nachnameInput" class="form-control" [ngClass]="{ 'is-invalid': submitted && getControl.nachnameInput.errors }" />
                <div *ngIf="getControl.nachnameInput.touched && getControl.emailInput.invalid" class="text-danger">
                  <div *ngIf="getControl.nachnameInput.errors.required">Name is required.</div>
                  <div *ngIf="getControl.nachnameInput.errors.nachnameInput">Name seems invalid.</div>
                </div>
              </div>

                <div class="form-group">
                  <label>Your Email*</label>
                  <input type="text" formControlName="emailInput" class="form-control" [ngClass]="{ 'is-invalid': submitted && getControl.emailInput.errors }" />
                  <div *ngIf="getControl.emailInput.touched && getControl.emailInput.invalid" class="text-danger">
                    <div *ngIf="getControl.emailInput.errors.required">Email is required.</div>
                    <div *ngIf="getControl.emailInput.errors.emailInput">Email seems invalid.</div>
                  </div>
                </div>

              <div class="form-group">
                <label>Your Message*</label>
                <textarea type="text" formControlName="nachrichtInput" class="form-control" [ngClass]="{ 'is-invalid': submitted && getControl.nachrichtInput.errors }"></textarea>
                <div *ngIf="getControl.nachrichtInput.touched && getControl.nachrichtInput.invalid" class="text-danger">
                  <div *ngIf="getControl.nachrichtInput.errors.required">Message is required.</div>
                  <div *ngIf="getControl.nachrichtInput.errors.nachrichtInput">Message seems invalid.</div>
                </div>
              </div>
                <button class="btn-common" type="submit">Submit</button>

              </form>


          </div>
          <div class="col-sm-6">
            <div class="inner-text text-center">
              <h2>Make Your ideas Come to <br> life with a <span class="common-g">ZOOME</span> site</h2>
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a class="btn-common" routerLink="/uniswap">Buy Token</a>
                </li>
                <!-- <li class="list-inline-item">
                    <a class="btn-common">Get Started</a>
                </li> -->
              </ul>
            </div>
          </div>

        </div>
    </div>

  <div class="position-fixed bottom-0 right-0 p-3" style="z-index: 5; right: 0; bottom: 0;">
    <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
      <div class="toast-header">
        <img src="../../assets/zoome.svg" width="100%" class="img-fluid mr-2 " alt="ZOOME logo" style="background: #555555;padding: 5px;">
      </div>
      <div class="toast-body" style="background: #dc3545;padding: .5rem .75rem;">
        Hello! There is an error in your message.
      </div>
    </div>
  </div>

  <div class="position-fixed bottom-0 right-0 p-3" style="z-index: 5; right: 0; bottom: 0;">
    <div id="successLiveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
      <div class="toast-header">
        <img src="../../assets/zoome.svg" width="100%" class="img-fluid mr-2 " alt="ZOOME logo" style="background: #555555;padding: 5px;">
      </div>
      <div class="toast-body" style="background: #3ea323;padding: .5rem .75rem;">
        Hello! Your message is sent.
      </div>
    </div>
  </div>


</section>

<div class="modal" tabindex="-1" id="myModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" style="color: #FFFFFF;">Your <span class="common-g"> ZOOME</span> Token on Uniswap</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="closeModel()">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe
          src="https://app.uniswap.org/#/swap?use=v2&exactField=input&exactAmount=1&inputCurrency=ETH&outputCurrency=0x0b259c2da43b33d9d81d540a8ade80409b19df5f"
          height="660px"
          width="100%"
          style=" border: 0;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
  "
        ></iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-common" data-dismiss="modal" (click)="closeModel()">Close</button>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

