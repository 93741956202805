import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import {UniswapComponent} from './uniswap/uniswap.component';

const routes: Routes = [
  {
    path:'', component:LandingComponent,
  },
  {
    path:'uniswap', component:UniswapComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
