import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { MetamaskProvider } from "@0xcert/ethereum-metamask-provider";
import { WEB3 } from '../web3';
import Web3 from 'web3';
import * as $ from 'jquery';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-uniswap',
  templateUrl: './uniswap.component.html',
  styleUrls: ['./uniswap.component.scss']
})
export class UniswapComponent implements OnInit {
  model: any = {};
  showcopy: boolean;
  showcopy1: boolean;
  timer;
  compareDate = new Date('June 25, 2021 00:00:00');
  connected: boolean = false;
  //new
  ethBalance: any;
  ethAddress: any;
  ethAddressList: any[];
  baseCurrency: String = 'ETH';
  name: String;
  constructor(private zone: NgZone, @Inject(WEB3) private web3: any, private Web3: Web3 , private router:Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.model.address = '0x3EC905837DeFE90DFe88C184fd9718176470dCf1';
    this.model.addressContract = '0x0B259C2Da43B33d9D81D540a8aDe80409b19DF5F';

    setInterval(() => {
      this.timeBetweenDates();
    }, 1000);
    console.log("timer", this.timer);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  timeBetweenDates() {

    var future = new Date("Jun 09, 2021 15:00:00");
    var now = new Date();
    var diff = future.getTime() - now.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor(diff / (1000 * 60 * 60));
    var mins = Math.floor(diff / (1000 * 60));
    var secs = Math.floor(diff / 1000);
    var d = days;
    var h = hours - days * 24;
    var m = mins - hours * 60;
    var s = secs - mins * 60;
    $("#days").text(d);
    $("#hours").text(h);
    $("#minutes").text(m);
    $("#seconds").text(s);

  }

  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.model.address;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showcopy = true;
    setTimeout(() => {
      this.showcopy = false;
    }, 2000);
  }

  copyMessageContractAddress() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.model.addressContract;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showcopy1 = true;
    setTimeout(() => {
      this.showcopy1 = false;
    }, 2000);
  }
  async enableMetamask() {
    try {
      const provider = new MetamaskProvider();
      let boolean = await provider.isEnabled();
      if (!boolean) {
        let conn = await provider.enable();
        if (conn) {
          this.connected = true;
          alert('Connected')
        } else {
          alert('Please install metamask')
        }
        boolean = true;
      } else {
        alert("Already connected or install extension");
      }
    }
    catch (err) {
      alert(err.message);
    }
  }

  async enableMetamaskwithtoken() {
    console.log(this.model);
    var amount = this.model.amount;
    try {
      const provider = new MetamaskProvider();
      let boolean = await provider.isEnabled();
      if (!boolean) {
        let conn = await provider.enable();
        if (conn) {
          this.connected = true;
          // alert('Connected')
        } else {
          alert('Please install metamask')
        }
        boolean = true;
      } else {
        alert("Already connected or install extension");
      }
    }
    catch (err) {
      alert(err.message);
    }

    this.name = 'ETHEREUM';
    // var Web3: any = Web3;

    var jsonData: any = [
      {
        "constant": true,
        "inputs": [],
        "name": "hasClosed",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "rate",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "weiRaised",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "isOpen",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "closingTime",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "wallet",
        "outputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "isOwner",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          {
            "name": "newClosingTime",
            "type": "uint256"
          }
        ],
        "name": "extendTime",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "openingTime",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "remainingTokens",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "tokenWallet",
        "outputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          {
            "name": "beneficiary",
            "type": "address"
          }
        ],
        "name": "buyTokens",
        "outputs": [],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          {
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "token",
        "outputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "name": "Zoome",
            "type": "address"
          },
          {
            "name": "wallet",
            "type": "address"
          },
          {
            "name": "tokenWallet",
            "type": "address"
          },
          {
            "name": "rate",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "name": "purchaser",
            "type": "address"
          },
          {
            "indexed": true,
            "name": "beneficiary",
            "type": "address"
          },
          {
            "indexed": false,
            "name": "value",
            "type": "uint256"
          },
          {
            "indexed": false,
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "TokensPurchased",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "name": "prevClosingTime",
            "type": "uint256"
          },
          {
            "indexed": false,
            "name": "newClosingTime",
            "type": "uint256"
          }
        ],
        "name": "TimedCrowdsaleExtended",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      }
    ];
    if (typeof this.web3 !== 'undefined') {

      var w3js: any = new Web3(this.web3.currentProvider);
      var mythis = this;
      var contractAddress = "0x01AafD153Bb178957492b076f196cFeDAb1e2AA5";
      w3js.eth.getAccounts(async function (err, res) {
        mythis.ethAddressList = [];
        console.log(res);

        if (res) {
          var tokenInst = await new w3js.eth.Contract(jsonData, contractAddress);
          await tokenInst.methods.buyTokens(res[0]).send({ from: res[0], gas: '210000', gasPrice: w3js.utils.toWei('30', 'gwei'), value: w3js.utils.toWei(JSON.stringify((amount / 25000)), 'ether') });

        } else {
          mythis.zone.run(() => {
            let obj = {

              address: 'Please authorise you account to view',

              balance: 'Please authorise you account to view'

            };
            mythis.ethAddressList.push(obj);
          });
        }
      });
    } else {
      alert('Web3 Not Supported/not autorized');
    }

  }

}
