<section class="footer ptb">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="inner-logo">
                  <img src="../../assets/zoome.svg" alt="zoome ecommerce solutions" width="20%" height="100%" class="img-fluid">

                  <div class="inner-nav">
                            <ul class="list-inline">
                                <li class="list-inline" >
                                    <a  class="top" href="#" aria-disabled="true">Back To Top</a>
                                  </li>
                                <!-- <li class="">
                                    <a>Key Features</a>
                                </li>
                                <li class="">
                                    <a>User Testimonials</a>
                                </li> -->
                            </ul>

                        </div>
                </div>
            </div>
            <!-- <div class="col-sm-4">
                <div class="inner-nav">
                    <h5>Products and services</h5>
                    <ul class="">
                        <li class="">
                            <a>Make your own website or blog</a>
                        </li>
                        <li class="">
                            <a>Make your own business website</a>
                        </li>
                        <li class="">
                            <a>Templates</a>
                        </li>
                        <li class="">
                            <a>Open online store</a>
                        </li>
                        <li class="">
                            <a>Domain</a>
                        </li>
                        <li class="">
                            <a>Premium Plans</a>
                        </li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="col-sm-4">
                <div class="inner-nav text-end">
                    <h5>Follow Us</h5>
                    <ul class="">
                        <li class="ml">
                            <a href="" target="_blank"><img src="../../assets/zoome_google_docs.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://www.facebook.com/zoome.io" target="_blank"><img src="../../assets/zoome_facebook.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://twitter.com/IoZoome" target="_blank"><img src="../../assets/zoome_twitter.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://www.instagram.com/zoomeecommerce/" target="_blank"><img src="../../assets/zoome_instagram.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://t.me/zoomeIo" target="_blank"><img src="../../assets/zoome_telegram.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://github.com/zoomeIo" target="_blank"><img src="../../assets/zoome_github.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://www.youtube.com/channel/UCMV_liPgAnjo3McVqdYByNw" target="_blank"><img src="../../assets/zoome_youtube.png " class="img-fluid img-social"></a>
                        </li>
                        <li class="ml">
                            <a href="https://www.reddit.com/user/zoomeIo" target="_blank"><img src="../../assets/zoome_reddit.png" class="img-fluid img-social"></a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-sm-2">
                <div class="inner-nav">
                    <h5>Languages</h5>
                    <ul class="">
                        <li class="">
                            <a>English</a>
                        </li>
                        <li class="">
                            <a>Deutsch</a>
                        </li>
                        <li class="">
                            <a>Slovencina</a>
                        </li>
                        <li class="">
                            <a>Italian</a>
                        </li>
                        <li class="list-inline-item">
                            <a href="">See All</a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-6">
                <div class="inner-copy inner-nav">
                  <ul class="list-inline">
                    <li class="nav-item">
                      &copy; ZOOME 2021. All Rights Reserved.
                    </li>
                  </ul>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="inner-copy">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <a href="" target="_blank"><img src="../../assets/zoome_google_docs.png" class="img-fluid img-sociall"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.facebook.com/zoome.io" target="_blank"><img src="../../assets/zoome_facebook.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://twitter.com/IoZoome" target="_blank"><img src="../../assets/zoome_twitter.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.instagram.com/zoomeecommerce/" target="_blank"><img src="../../assets/zoome_instagram.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://t.me/zoome_io" target="_blank"><img src="../../assets/zoome_telegram.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://github.com/zoomeIo" target="_blank"><img src="../../assets/zoome_github.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.youtube.com/channel/UCMV_liPgAnjo3McVqdYByNw" target="_blank"><img src="../../assets/zoome_youtube.png " class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.reddit.com/user/zoomeIo" target="_blank"><img src="../../assets/zoome_reddit.png" class="img-fluid img-social"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.reddit.com/user/zoomeIo" target="_blank"><img src="../../assets/zoome_linkedin.png" class="img-fluid img-social"></a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
