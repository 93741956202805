<section class="main-banner" #target>
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">
        <img src="../../assets/zoome.svg" alt="zoome ecommerce solutions" width="75%" height="100%" class="img-fluid">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Why Us</a>
                </li> -->
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(targetfeatures)">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(targetabout)">How it will work</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(targetbuy)">How to Buy</a>
          </li>
        </ul>
        <!--<form class="form-inline my-2 my-lg-0 ml">
          <ul class="list-inline">
            <div *ngIf="!connected">
              <li class="list-inline-item">
                <a class="btn-common" (click)="enableMetamask()">Connect</a>
              </li>
            </div>
            <div *ngIf="connected">
              <li class="list-inline-item">
                <a class="btn-common">Connected</a>
              </li>
            </div>
          </ul>
        </form>-->
      </div>
    </nav>
    <div class="row ptb">
      <div class="col-sm-12 animate__animated animate__backInLeft">
        <div class="inner-box text-center">
          <h1><span class="common-g"> ZOOME</span> Token on Uniswap</h1>
          <p>ZOOME is the high-performance CMS system that is easy to use, without any programming
            knowledge. The integrated shop function gives you the opportunity to start your eCommerce
            business in a short time.</p>
          <div class="row">
            <div class="offset-sm-2 col-sm-8">
              <iframe
                src="https://app.uniswap.org/#/swap?use=v2&exactField=input&exactAmount=1&inputCurrency=ETH&outputCurrency=0x0b259c2da43b33d9d81d540a8ade80409b19df5f"
                height="660px"
                width="100%"
                style=" border: 0;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
  "
              ></iframe>
            </div>
           <!-- <div class="col-sm-4 animate__animated animate__backInRight">
              <div class="inner-boxy">
                <h3 class="clr text-center">Presale Ends in</h3>

                <div class="inner-style">
                  <div class="row">
                    <div class="col-sm-12">
                      <ul id="timer" class="list-inline text-center">
                        <li class="list-inline-item">
                          <h2 id="days" class="common-g">03</h2>
                          <h6 class="common-g"><strong>DAYS</strong></h6>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="days" class="common-g">:</h2>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="hours" class="common-g">05</h2>
                          <h6 class="common-g"><strong>HRS</strong></h6>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="days" class="common-g">:</h2>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="minutes" class="common-g">15</h2>
                          <h6 class="common-g"><strong>MIN</strong></h6>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="days" class="common-g">:</h2>
                        </li>
                        <li class="list-inline-item">
                          <h2 id="seconds" class="common-g">30</h2>
                          <h6 class="common-g"><strong>SEC</strong></h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control for-input" [(ngModel)]="model.amount" #amount="ngModel"
                               name="amount" placeholder="Enter Amount Of Tokens" aria-label="Recipient's username"
                               aria-describedby="basic-addon2">
                        <button class="btn-common" (click)="enableMetamaskwithtoken()">Buy ZOE Token Now</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="tokens">
                      <span class="common-g">Token Price:</span>
                      <h6>1 ZOE = $0.10</h6>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="tokens">
                      <span class="common-g">We Accept:</span>
                      <h6>Ethereum</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
            <div class="col-sm-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="features ptb" #targetfeatures>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="main-color text-center">
          <span class="common-g"> ZOOME</span>
          Token Features
        </h2>
        <span class="border1"></span>
      </div>
    </div>

    <div class="row ptb">
      <div class="col-sm-4">
        <div class="inner-side">
          <img src="../../assets/zoome-presale-assest/lowest-cost-icon.png" alt="" class="img-fluid">
          <h3 class="main-color">Lowest Cost</h3>
          <p>ZOOME offers a variety of widgets with which you can create beautiful websites without
            programming knowledge.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="inner-side">
          <img src="../../assets/zoome-presale-assest/safe-contract-icon.png" alt="" class="img-fluid">
          <h3 class="main-color">
            Safe Contract
          </h3>
          <p>Our CMS includes a pre-installed shop module with which you can start your eCommerce
            business in just a few steps.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="inner-side">
          <img src="../../assets/zoome-presale-assest/fast-blockchain-icon.png" alt="" class="img-fluid">
          <h3 class="main-color">
            Fast Blockchain
          </h3>
          <p>The ZOOME shop module allows you to offer crypto currency as a payment method. Get the
            competitive advantage.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="round ptb">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="inner-img">
          <img src="../../assets/what-is-zoome-image.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="inner-text">
          <h2>What is <span class="common-g">ZOOME</span></h2>
          <p class="p1">Create your fully responsive high-performance website using drag and drop. Our CMS system
            offers you numerous exciting and effective advertising widgets and creative, pre-designed
            templates that leave a lasting impression on your visitors.</p>
          <ul class="list-inline for-m">
            <li class="list-inline-item">
              <a class="btn-common" href="../../assets/ZOOME white paper.pdf"  target="_blank">White Paper</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="round2 ptb" #targetabout>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="inner-text">
          <h2> How it
            <span class="common-g">will work?</span>
          </h2>
          <p class="p1">Everyone is talking about crypto right now. So why not integrate this currency into a shop
            system? Be one of the first to enable your customers to pay for products with Bitcoin, Ethereum
            and Co.</p>
          <ul class="list-inline for-m">
            <li class="list-inline-item">
              <a class="btn-common" href="../../assets/ZOOME white paper.pdf"  target="_blank">White Paper</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="inner-img">
          <img src="../../assets/how-its-work-image.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="token">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="common-g">Token Distribution</h2>
      </div>
    </div>
    <div class="row ptb">
      <div class="col-sm-4">
        <div class="inner-tile">
          <div class="row">
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Name:
                <h6 class="common-g">ZOOME</h6>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Presale Starts:
                <h6 class="common-g">Jun 02, 2021</h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Ticker:
                <h6 class="common-g">ZOE</h6>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Presale Ends:
                <h6 class="common-g">Jun 09, 2021</h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Total Supply:
                <h6 class="common-g">500,000,000</h6>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="inner-style">
                <span class="common-g"></span>
                Accepted Currency:
                <h6 class="common-g">ETH</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="inner-img text-center">
          <img src="../../assets/zoome-presale-assest/token-distribution-pie.png" alt="" class="img-fluid"
               style=" width: 256px;">
        </div>
      </div>

      <div class="col-sm-4 pt-4">
        <img src="../../assets/zoome-presale-assest/token-distribution.png" class="img-fluid">
      </div>
    </div>

    <div class="row ptb">
      <div class="col-sm-6">
        <div class="inner-box">
         <!-- <h3 class="text-center">Presale</h3>
          <p class="text-center">A limited-time sale of ZOE at a discount for early investors.</p>
          <h2 class="common-g text-center">1 ETH = ~26,000 $ZOE</h2>
          <div *ngIf="!connected">
            <button class="btn-common" style="margin: 20px 0;" (click)="enableMetamask()">CONNECT TO METAMASK</button>
          </div>
          <div *ngIf="connected">
            <button class="btn-common" style="margin: 20px 0;">Wallet Connected</button>
          </div>-->
          <iframe
            src="https://app.uniswap.org/#/swap?use=v2&exactField=input&exactAmount=1&inputCurrency=ETH&outputCurrency=0x0b259c2da43b33d9d81d540a8ade80409b19df5f"
            height="660px"
            width="100%"
            style=" border: 0;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
  "
          ></iframe>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="inner-box">
          <h3>Public Sale Address:</h3>
          <ul class="list-inline" style="margin: 20px 0 0px;">
            <li class="list-inline-item">
              <div class="form-group">
                <input type="email" class="form-control" [(ngModel)]="model.address" #address="ngModel"
                       aria-describedby="emailHelp" placeholder="0x3EC905837DeFE90DFe88C184fd9718176470dCf1">
              </div>
            </li>
            <li class="list-inline-item">
              <div class="inner-btn">
                <button class="btn-common" (click)="copyMessage()">COPY</button>
              </div>
            </li>
            <li class="list-inline-item">
              <div class="inner-btn" *ngIf="showcopy">
                <button class="btn-common">COPIED</button>
              </div>
            </li>
          </ul>
        </div>

        <div class="inner-box">
          <h3>Contract Address:</h3>
          <ul class="list-inline" style="margin: 20px 0 0px;">
            <li class="list-inline-item">
              <div class="form-group">
                <input type="email" class="form-control" [(ngModel)]="model.addressContract" value=""
                       placeholder="0x0B259C2Da43B33d9D81D540a8aDe80409b19DF5F">
              </div>
            </li>
            <li class="list-inline-item">
              <div class="inner-btn">
                <button class="btn-common" (click)="copyMessageContractAddress()">COPY</button>
              </div>
            </li>
            <li class="list-inline-item">
              <div class="inner-btn" *ngIf="showcopy1">
                <button class="btn-common">COPIED</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="roadmap ptb">
  <div class="container">
    <div class="row">

      <div class="col-sm-12">
        <div class="inner-text text-center">
          <h2 class="common-g">ZOOME Roadmap</h2>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">
        <!-- <img src="../../assets/zoome-presale-assest/roadmap.png" alt="" class="img-fluid"> -->
        <div class="timeline">
          <ul>
            <li>
              <div class="right_content">
                <p>Concept Creation
                </p>
                <p>Creation of an overarching marketing strategy</p>
              </div>
              <div class="left_content">
                <h3>Q1 - 2021 </h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>Concept Validation
                </p>
                <p>White Paper Creation</p>
                <p>Token creation
                </p>
                <p>Website Creation
                </p>
                <p>Presale
                </p>
              </div>
              <div class="left_content">
                <h3>Q2 - 2021 </h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>Start of Development
                </p>
                <p>Listings on famous DEXs (UniSwap, SushiSwap)
                </p>
                <p>Development of CMS

                </p>
              </div>
              <div class="left_content">
                <h3>Q3 - 2021</h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>Listings on CoinMarketCap and CoinGecko

                </p>
                <p>Integration of Crypto payment providers e.g. CoinPayments

                </p>
                <p>Community votes, events and competitions


                </p>
              </div>
              <div class="left_content">
                <h3>Q4 - 2021</h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>Integration of native FIAT to Crypto exchange

                </p>
                <p>Integration of native Crypto to FIAT exchange

                </p>
                <p>Partnerships with new payment providers
                </p>
              </div>
              <div class="left_content">
                <h3>Q1 - 2022</h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <h2></h2>
                <p>Integration of ZOE for payments
                </p>
                <p>Gifting of crypto assets
                </p>
                <p>Huge Marketing Push
                </p>
              </div>
              <div class="left_content">
                <h3>Q2 - 2022</h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>NFT marketplace/e-commerce platform
                </p>
                <p>Zoome mobile application
                </p>
              </div>
              <div class="left_content">
                <h3>Q3 - 2022</h3>
              </div>
            </li>
            <li>
              <div class="right_content">
                <p>Reward Program for early users
                </p>
                <p>Zoome mobile and web wallet </p>
              </div>
              <div class="left_content">
                <h3>Q4 - 2022</h3>
              </div>
            </li>
            <div style="clear:both;"></div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="buy-zoe ptb" #targetbuy>
  <div class="container">
    <div class="row ">
      <div class="col-sm-12">
        <div class="zoe">
          <h2>How to buy <span class="common-g">$ZOE?</span> </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="">
          <p><span class="common-g">1.</span> Create an account at <span class="common-g">binance.com, kraken.com,
              bitpanda.com, kukoin.com </span>or an exchanger of
            your choice. Exchange FIAT (Dollar, Euro, Franc) for Ethereum ($ETH).</p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="">
          <p>
            <span class="common-g">2.</span> The safest place to buy $ZOE is on <span class="common-g">ZOOME.io.</span>
            Visit <span class="common-g">ZOOME.io </span>and click “connect wallet”
            MetaMask will ask for your signature, go ahead and sign it.
          </p>

        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 ">
        <div class="">
          <p><span class="common-g">3.</span> Open your Google Chrome and visit <span
            class="common-g">metamask.io.</span> Download the MetaMask chrome extension and
            set up a wallet. On mobile? Get MetaMask’s app for iPhone or Android.</p>

        </div>
      </div>
      <div class="col-sm-6">
        <div class="">
          <p><span class="common-g">4.</span> Set your slippage to 3-4% and enter and the amount of $ETH you would like
            to swap for
            $ZOE.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row ">
      <div class="d-flex col-sm-6 ">
        <div class="">
          <p>
            <span class="common-g">5.</span> Transfer $ETH to your new MetaMask wallet from your existing wallet (e.g.
            Trust Wallet,
            Coinbase, or Binance), or buy $ETH directly within MetaMask.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row ">
      <div class=" col-sm-12">
        <div class="buy-button">
          <ul class="list-inline for-m">
            <li class="list-inline-item">
              <a (click)="scroll(target)" class="btn-common">Buy Token</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="round ptb">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                 <div class="inner-img">
                     <img src="../../assets/start-selling-img.png" alt="" class="img-fluid">
                 </div>
            </div>
            <div class="col-sm-6">
                <div class="inner-text">
                    <h2>Start Selling at labore et <br>dolore <span class="common-g">magna aliqua</span></h2>
                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <ul class="list-inline for-m">
                         <li class="list-inline-item" >
                             <a  class="btn-common" target="_blank">Get Started</a>
                           </li>
                     </ul>
                 </div>
             </div>
        </div>
    </div>
 </section> -->
<!-- <section class="faqs ptb">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2 class="common-g text-center">Frequently Asked Questions</h2>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="inner-accord">
                            <div id="accordion">
                                <div class="card">
                                  <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Collapsible Group Item #1
                                      </button>
                                    </h5>
                                  </div>

                                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Collapsible Group Item #2
                                      </button>
                                    </h5>
                                  </div>
                                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #3
                                      </button>
                                    </h5>
                                  </div>
                                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="inner-accord">
                            <div id="accordion">
                                <div class="card">
                                  <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Collapsible Group Item #1
                                      </button>
                                    </h5>
                                  </div>

                                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Collapsible Group Item #2
                                      </button>
                                    </h5>
                                  </div>
                                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                      <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #3
                                      </button>
                                    </h5>
                                  </div>
                                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="roadmap2 ">
  <div class="container">
    <div class="row ptb">
      <div class="col-sm-12">
        <div class="inner-text text-center">
          <h2 class="common-g">JOIN OUR TELEGRAM CHANNEL</h2>
          <p>Talk directly to the team & the community</p>
          <ul class="list-inline for-m">
            <li class="list-inline-item">
              <a class="btn-common" target="_blank" href="https://t.me/zoome_io">Join Now</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
