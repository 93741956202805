import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators, FormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
declare var $: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  validatingForm: FormGroup;
  submitted = false;

  constructor(private http: HttpClient, public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.validatingForm = this.formBuilder.group({
      nachnameInput: ['', [Validators.required, Validators.minLength(4)]],
      emailInput: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      nachrichtInput: ['', [Validators.required, Validators.minLength(20)]]
    });
    $('#token-search-input').val('ZOE')
    $("#myModal").modal("show");

  }

  closeModel() {
    $("#myModal").modal("hide");
  }
  get getControl(){
    return this.validatingForm.controls;
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.validatingForm.invalid) {
      $("#liveToast").toast('show');
      return;
    }
    else{
      $("#successLiveToast").toast('show');
      let data = this.validatingForm.value;
      this.http.post('phpmailer/sendEmail.php', data).subscribe((data:Response) => {
        error: error => console.error('There was an error!', error);
      });
    }


  }
}
